@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
*
{
    padding: 0;
    margin: 0;
    /* outline: 1px solid #000; */
}
html {
    scroll-behavior: smooth;
}
body
{
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}
.ant-btn 
{
  font-family: "Poppins", sans-serif;
}
.ant-btn-primary {
  color: #fff;
  background: #5562da;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #3c4cdf;
}
a {
    text-decoration: none !important;
}
/* img {
    width: 100%;
} */
a:hover{
  color: #5562DA !important;
}
p 
{
    font-size: 16px;
}
ul
{
    padding-left: 0;
}
li,
a {
    text-decoration: none;
    list-style: none;
    color: unset;
}
a:hover {
  color: #009dcf;
}
h1,h2,h3,h4,h5,h6{
    font-family: "Poppins", sans-serif;
    /* font-weight: bold; */
    font-style: normal;
    color: #4E6A9F;
}
/* common */
.btn {
  font-weight: 500 !important;
}
.btn-primary {
    color: #fff;
    background-color: rgb(85, 98, 218);
    border-color: rgb(85, 98, 218) !important;
}
.btn-outline-primary {
    color: rgb(85, 98, 218);
    border-color: rgb(85, 98, 218) ;
}
.btn-primary:hover {
    color: #fff;
    background-color: #1d41a7;
    border-color: #1d41a7 !important;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: rgb(85, 98, 218);
    border-color: rgb(85, 98, 218);
}
label
{
    color: #262626;
}
.form-select
{
    background: url(../images/downArrow.png) no-repeat !important;
    background-size: 20px 20px !important;
    background-position: right 0.75rem center !important;
    border-color: #082064;
    border-radius: 5px;
}
.form-select::after
{
    content: "";
    border: 1px solid #000;
}
.line-select
{
    width: 1px;
    height: 100%;
    background: #082064;
    position: absolute;
    right: 47px;
}
.select-line
{
    position: relative;
    display: flex;
}
.radio {
    position: relative;
    margin: 13px;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 2px solid #082064;
    border-radius: 50%;
}

.radio:hover input~.checkmark {
    background-color: #fff;
}

.radio input:checked~.checkmark {
    border: 2px solid #082064;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio input:checked~.checkmark:after {
    display: block;
}

.radio .checkmark:after {
    top: 1px;
    left: 1px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #082064;
}
/*  */
.custom-dropdown-toggle {
  position: relative;
  padding-right: 25px; 
  color: #5562DA;

}

.custom-dropdown-toggle::after {
  content: "";
  position: absolute;
  bottom: 8px;
  right: 7px;
  width: 15px; 
  height: 15px; 
  background-image: url('../images/dropdown-arrow.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
}
.sort-btn .custom-dropdown-toggle::after
{
  top: 15px;
    right: 7px;
}
/*  */
.border-right-line
{
    border-right: 1px solid #082064;
}
/*  */
.bg-body-section
{
    background: #082064;
}
img 
{
    width: 100%;
}
.login-logo-img
{
    /* width: 225px; */
    width: 100%;
}
.login-box
{
    padding: 100px 40px;
    background: #fff;
    border-radius: 45px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.login-box .form-control, .login-box .form-select
{
    padding: 0.575rem 0.95rem;
    border-radius: 5px;
}

.form-control
{
    border: 1px solid rgb(222, 222, 222);
    border-radius: 5px !important;
}
.border-radiuss
{
  border-radius: 5px !important;
}
.login-box label
{
    margin-left: 5px;
}
.login-box .radio label
{
    margin-left: 25px;
}
.btn 
{
    border-radius: 6px;
    padding: 0.475rem 1.5rem;
    font-size: 15px;
    border: 2px solid;
    font-weight: 600;
}
.login-box .btn
{
    font-weight: 500;
}
/* start */
/* mobile view */
.nav-mobile {
	position: relative;
    display: none;
}

.nav-bar {
	position: fixed;
	background: #fff;
	top: 0;
	right: 100%;
	height: 100vh;
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: position 2.9s;
	-webkit-transition: position 2.9s;
	-moz-transition: position 2.9s;
	-ms-transition: position 2.9s;
	-o-transition: position 2.9s;
    z-index: 9999;
    overflow-y: auto;
}

.toggle {
	left: 0;
	box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.4);
    padding-top: 80px;
}

.toggle-menu {
	/* background-color: rgba(0, 0, 0, 0.2); */
	position: fixed;
	top: 15px;
    left: 20px;
	width: 40px;
	height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    padding: 2px 2px 2px 3px;
	border-radius: 5px;
	cursor: pointer;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
    z-index: 9999;
}

.line {
	width: 100%;
	height: 4px;
	border-radius: 5px;
	background-color: #000;
	transition: transform 0.2s ease-out;
	-webkit-transition: transform 0.2s ease-out;
	-moz-transition: transform 0.2s ease-out;
	-ms-transition: transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
}

.toggle .line1 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(-45deg) translate(-6px, 4px);
}

.toggle .line2 {
	display: none;
}

.toggle .line3 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(45deg) translate(-6px, -4px);
}

.toggle .toggle-menu {
	background-color: white;
}

.nav-list {
	list-style: none;
}

.nav-list-item {
	text-align: left;
    padding: 10px 0px 10px 4px;
}

.header__nav .nav-link {
	color: #00B8F1;
	font-size: 19px;
	text-decoration: none;
	position: relative;
	padding-bottom: 4px;
}

.header__nav .nav-link::before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: #fff;
	transform: scaleX(0);
	transition: transform 0.4s ease-in-out;
	transform-origin: left;
	-webkit-transition: transform 0.4s ease-in-out;
	-moz-transition: transform 0.4s ease-in-out;
	-ms-transition: transform 0.4s ease-in-out;
	-o-transition: transform 0.4s ease-in-out;
}

.header__nav .nav-link:hover::before {
	transform: scaleX(1);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* end mobile view */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background: #082064;
    z-index: 999;
    transition: all 0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 0 15px; */
    overflow-y: auto;
  }
  .sidebar.close {
    width: 78px;
  }
  .sidebar.close .accordion-item .accordion-body {
    display: none;
  }
  .sidebar .logo-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar .logo-details i {
    font-size: 30px;
    color: #00B8F1;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
  }
  .sidebar .logo-details .logo_name {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
  }
  .sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
  }
  .sidebar .logo-details .d-logo{
    display: none;
  }
  .sidebar .logo-details .full-logo{
    display: block;
  }
  .sidebar .logo-details .full-logo img{
    width: 175px;
  }
  .sidebar.close .logo-details .full-logo{
    display: none;
  }
  .sidebar.close .logo-details .d-logo{
    display: block;
  }
  
  
  .sidebar .nav-links {
    /* height: 100%; */
    padding: 0 0 15px 0;
    overflow: auto;
    margin-top: 20px;
  }
  .sidebar.close .nav-links {
    overflow: visible;
  }
  .sidebar .nav-links::-webkit-scrollbar {
    display: none;
  }
  .sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
    /* margin-bottom: 20px; */
    padding-left: 15px;
    /* border-bottom: 1px solid #0820645c; */
    padding: 10px 0;
  }
  .sidebar .nav-links li:hover a span {
    color: #ffffff;
  }
  .sidebar .nav-links li .icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar.close .nav-links li .icon-link {
    display: block;
  }
  .sidebar .nav-links li i {
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .sidebar .nav-links li ul li 
  {
    margin-bottom: 0;
    padding-left: 56px !important;
  }
  .sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
  }
  .sidebar.close .nav-links i.arrow {
    display: none;
  }
  .sidebar .nav-links li a {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 5px 10px;
    border-radius: 12px;
  }
  .sidebar .nav-links li a .link_name {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    transition: all 0.4s ease;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sidebar .nav-links li a .link_name.active 
  {
      /* font-weight: 700; */
  }
  .sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
    display: none;
  }
  .sidebar .nav-links li .sub-menu {
    padding: 6px;
    margin-top: 0px;
    background: #082064;
    display: none;
  }
  .sidebar .nav-links li.showMenu .sub-menu {
    display: block;
  }
  .sidebar .nav-links li .sub-menu a {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
    justify-content: flex-start;
  }
  .sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
  }
  .sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
  }
  .sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
  }
  /* .sidebar .nav-links li .sub-menu .link_name {
    display: none;
  } */
  .sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 15px;
    opacity: 1;
    display: block;
  }
  .sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 3px 16px;
    opacity: 0;
    pointer-events: none;
  }
  .sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
  }
  .sidebar.close .profile-details {
    background: none;
  }
  .sidebar.close .profile-details {
    width: 78px;
  }
  .sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
  }
  .sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
  }
  .sidebar.close .profile-details img {
    padding: 10px;
  }
  .sidebar .profile-details .profile_name,
  .sidebar .profile-details .job {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
  }
  .sidebar.close .profile-details i,
  .sidebar.close .profile-details .profile_name,
  .sidebar.close .profile-details .job {
    display: none;
  }
  .sidebar .profile-details .job {
    font-size: 12px;
  }
  .home-section {
    position: relative;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    background: #fff;
  }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
  .home-section .home-content {
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 23px 0;
    position: fixed;
    top: 0;
    z-index: 99;
    width: inherit;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .home-section .home-content .bx-menu,
  .home-section .home-content .text {
    color: #11101d;
    font-size: 35px;
  }
  .home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 50%;
  }
  .home-section .home-content .bx-menu h4 {
    margin-bottom: 0;
    margin-left: 15px;
  }
  .home-section .home-content .text {
    font-size: 26px;
    font-weight: 600;
  }
  .sidebar .nav-links li a img {
    width: 22px;
  }
  .sidebar .nav-links li a img.arrow-icon
  {
      width: 20px;
  }
  @media (max-width: 420px) {
    .sidebar.close .nav-links li .sub-menu {
      display: none;
    }
  }
  .sidebar .nav-links li ul li.active{
    border: 0px solid #082064 !important;
 
  }
  .sidebar .nav-links li ul li.active a{
    color: #fff !important;
    opacity: 1 !important;
   }
  .sidebar .nav-links li ul li.active::before
  {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 17px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 16px solid #082064;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(180deg); 
  }
/* end */
.profile-img
{
    width: 55px;
    height: 55px;
}
.profile-img img 
{
    border-radius: 45px;
}
.profile-img-content
{
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
}
.profile-img-content p 
{
    font-size: 13px;
}
.btn-outline-secondary:hover {
    color: #082064;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: none;
}
.ms-n5 {
    margin-left: -40px;
}
.search-style input, .search-style input span button i
{
    color: #082064;
    border: 1px solid #082064 !important;
}
.btn-outline-secondary {
    color: #082064;
    border-color: #6c757d;
}

.sidebar-accordion .accordion-button:not(.collapsed) {
    color: #082064;
    background-color: transparent;
    box-shadow: none;
}
.sidebar-accordion .accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.sidebar-accordion .accordion-item {
    background-color: #fff;
    border: 0px solid rgba(0,0,0,.125);
}
.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .accordion-button::after {
    content: none; /* Remove the pseudo-element completely */
  }
  /* .sub-list
  {
    padding-left: 15px;
  } */
  .sidebar .accordion-body 
  {
      padding: 0;
  }
.sub-list li
{
    border-bottom: 0 !important;
    padding: 3px 0 !important;
}
.sub-list li a 
{
    justify-content: space-between !important;
}
.edit-round
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 15px;
    border: 1px solid #082064 !important;
    border-radius: 50px;
    background: #082064;
}
.edit-round i{
    color: #fff;
    font-size: 15px;
}
.sidebar .nav-links li:last-child
{
    border: 0;
}
.sidebar.close .search-style
{
    display: none;
}
/* end sidebar */
/* main */
.project-task {
    padding: 1rem 2rem;
    margin-top: 75px;
    background: #F1F3F6;
  }
  .bg-blue-th
  {
      background: #082064;
      color: #fff;
     
  }
  .bg-blue-th th{
    font-weight: 400;
  }
  .table-responsive
  {
      border-radius: 8px;
  }
  .table-header th {
    border: none; /* Remove borders */
  }
  .form-check-input:checked {
    background-color: #082064;
    border-color: #082064;
}
.modal-footer
{
    border-top: 0px solid #dee2e6;
    padding: 0 0.75rem 0.75rem 0.75rem;
}
.modal-content
{
    border-radius: 1.3rem;
    border: 0px solid rgba(0,0,0,.2);
}
.modal-header
{
    background: #082064;
    border-top-left-radius: calc(1.3rem - 1px);
    border-top-right-radius: calc(1.3rem - 1px);
    border-bottom: 0px solid #dee2e6;
    color: #fff;
}
.right-sidebar
{
    width: 250px;
    margin-top: 75px;
    padding: 15px;
    height: calc(100vh - 75px);
}
.right-sidebar ul li 
{
    padding: 15px 0 0;
    color: #082064;
    font-size: 17px;
    font-weight: 500;
}
.profile-img-main
{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    position: relative;
}
.profile-img-main img{
    border-radius: 50%;
}
.profile-box
{
    border: 1px solid #082064;
    width: 40%;
    margin: 0 auto;
    padding: 25px 35px;
    border-radius: 30px;
    text-align: left;
}
.profile-box label
{
    font-weight: 600;
    margin-bottom: 10px;
}
.profile-box p
{
    margin-bottom: 20px;
}
/* img upload */
.img-wrapper-upload
{
    position: absolute;
    bottom: -18px;
    z-index: 9;
    /* z-index: 999; */
    right: 8px;
    width: 19%;
}
.img-upload-btn
{
    padding: 5px;
    background: #082064;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* img upload */
.report-style
{
    border: 1px solid #082064;
    text-align: left;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.heading-report-sty
{
    padding: 15px 20px;
    background: #082064;
    color: #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.report-box
{
    padding: 15px 30px;
    position: relative;
}
.report-box label
{
    font-weight: 600;
    margin-bottom: 5px;
}
.report-box label p {
    margin-bottom: 20px;
}
.width-report
{
    width: 140px;
}
.bg-blue-box
{
    width: 100%;
    background: #082064;
    height: 35px;
    border-radius: 5px;
}
.table>:not(caption)>*>*
{
  padding: .5rem 1.5rem;
}
.report-box input, .select-line
{
    width: 40%;
}
.report-save
{
    position: absolute;
    right: 20px;
}
/*  */
/* dashboard */
 /* .graph-box-line
 {
    border: 1px solid #082064;
    border-radius: 20px;
    padding: 30px;
 } */
 /* .small-graph-box
 {
    border: 1px solid #082064;
    border-radius: 20px;
    padding: 15px;
    height: 100%;
 } */
 /* svg */

  
  .bg {
    fill: none;
    stroke-width: 13px;
    /* stroke: #e8e8e8; */
    stroke: #b1b1b1;
    
  }
  .per-graph
  {
    position: absolute;
    bottom: 25%;
    margin: 0;
    font-size: 28px;
    transform: rotate(100deg);
    right: 28px;
    background: transparent;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4B698C;
    font-weight: 600;
  }
  .per-graph-2
  {
    position: absolute;
    bottom: 25%;
    margin: 0;
    font-size: 25px;
    transform: rotate(100deg);
    right: 28px;
    background: #cc0001;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4B698C;
  }
  .svg-style 
  {
    width: 140px;
    position: relative;
    margin: 5px 12px;
    transform: rotate(260deg);
  }
  .svg-style [class^="meter-"] {
    fill: none;
    stroke-width: 13px;
    stroke-linecap: round;
    
  }
  
  .meter-1 {
    stroke-dasharray: 345;
    stroke-dashoffset: 100;
    animation: progress-1 1s ease-out; 
  }

  .meter-2 {
    stroke-dasharray: 283;
    stroke-dashoffset: 102;
    animation: progress-2 1s ease-out; 
  }
  
  
  @keyframes progress-1 {
      from {
          stroke-dashoffset: 360;
      }
      to {
          stroke-dashoffset: 100;
      }
  }
  
  @keyframes progress-2 {
    from {
      stroke-dashoffset: 360;
    }
    to {
      stroke-dashoffset: 160;
    }
  }
  
  @keyframes progress-3 {
    from {
      stroke-dashoffset: 360;
    }
    to {
      stroke-dashoffset: 250;
    }
  }

 /* end svg */
 /*  */
  .inside-box
  {
    background-color: #fff;
    border: 1px solid #EBEBEC;
    padding: 15px;
    border-radius: 20px;
    height: 100%;
  }
  .table-responsive
  {
    background: #fff;
    min-height: 550px;
  }
/*  */
/* progressbar */
.barcontainer {
    position: relative;
    transform: translateY(-8%);
    top: 50%;
    margin-left: 14px;
    width: 8px;
    height: 152px;
    float: left;
    background: #e6eaf0;
    border-radius: 20px;
}
.small-graph-box p 
{
    color: #082064;
}
.bar {
    background-color: #5562DA;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    -webkit-animation: grow 1.5s ease-out forwards;
    animation: grow 1.5s ease-out forwards;
    transform-origin: bottom;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.bar-subtitle p 
{
    margin-left: 13px;
    font-size: 9px;
    font-weight: 700;
    color: #082064;
}
/* end progressbar */
/*  */
table 
{
  vertical-align: middle;
  color: #4B698C;
}
table thead tr th
{
  background: #EAF2FC !important;
  color: #4B698C;

}
.table-striped>tbody>tr:nth-of-type(odd)>*
{
  background: #fff !important;
  --bs-table-accent-bg: #fff !important;
  --bs-table-bg-type: #fff !important;
}
.table-striped>tbody>tr:nth-of-type(even)>*
{
  background: #F5F8FE !important;

}
.custom-btn-sec
{
  border: 1px solid #DAE6F2 !important;
  padding: 2px 17px !important;
  color: #4B698C !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  font-weight: 400;
}
.custom-btn-sec:hover
{
  background-color: transparent !important;
}
table tr td, table tr th
{
  color: #4E6A9F !important;
  font-weight: 400;
}
.search-space .form-control {
    border-radius: 15px !important;
}
/*  */
/* end dashboard */

/* chat */
.profileimgchat
{
  /* width: 180px;
    height: 180px;
    margin: 0 auto; */
    width: 280px;
    margin: 0 auto;
}
.profileimgchat img 
{
  /* border-radius: 50%; */
    width: 100%;
    height: 100%;
}
.pic-chat-bot
{
  width: 45px;
    height: 45px;
}
.pic-chat-bot img 
{
  width: 33px;
    border-radius: 7px;
    /* height: 100%; */
}
.pic-chat-bot-user
{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #082064;
  border-radius: 50px;
  color: #fff;
  padding: 7px;
}
/* .pic-chat-bot-user img 
{
  width: 100%;
  border-radius: 7px;
} */
.pic-chat-bot-user p 
{
  margin-bottom: 0;
}
.box-chat
{
  /* border-radius: 15px; */
    /* background: #eaeefa; */
    color: #000;
    /* padding: 15px 20px; */
    position: relative;
    /* width: auto;
    margin-left: 20px; */
    width: 90%;
}
.chat-right-box p 
{
  margin-bottom: 0;
  background: #f4f4f4 !important;
    padding: 15px 20px;
    border-radius: 15px;
    width: fit-content;
}
.chat-msg
{
  margin-left: 30px;
}
.chat-msg p 
{
  margin-bottom: 0;
  background: #eaeefa !important;
    padding: 15px 20px;
    border-radius: 15px;
    width: fit-content;
}
.chat-msg:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid #eaeefa;
    border-top: 15px solid #eaeefa;
    border-bottom: 1px solid transparent;
    left: -16px;
    top: 0px;
}
.chat-right-box::before
{
  content: "";
    width: 0px;
    height: 0px;
    position: absolute;
  border-left: 15px solid #f4f4f4 !important;
  border-right: 15px solid transparent !important;
  border-top: 15px solid #f4f4f4 !important;
  border-bottom: 15px solid transparent !important;
  right: -16px !important;
  top: 17px !important;
}
.chat-right-box
{
  margin-right: 20px;
    margin-left: 0 !important;
    /* background: #f4f4f4 !important; */
    background: #f4f4f400 !important;
    /* width: auto; */
    display: flex;
    justify-content: end;
}
.response-side
{
  display: flex;
}
.chat-bot-msg
{
  display: flex;
  width: 97%;
  margin: 0 0 0 auto;
  justify-content: end;
}
.chat-box .inside-box {
  padding: 30px;
}
/* end chat */

/* .small-box
{
    border: 1px solid #082064;
    border-radius: 25px;
    width: 28%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.heading-box
{
    background: #082064;
    padding: 15px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}
.list-smallbox
{
    padding: 15px;
} */
/* .main-small-box
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    max-width: 1200px;
    margin: 0px auto;
    gap: 100px;
    position: relative;
} */

/* .center {
    height: 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    bottom: 46%;
    left: 40%;
} */

/* .center {
    height: fit-content;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    bottom: 35%;
    left: 40%;
}
.center::before
{
    content: '';
    width: 96%;
    height: 145px;
    border-top: 2px dashed #082064;
    border-right: 2px dashed #082064;
    position: absolute;
    right: 152px;
    bottom: 146px;
}
.center::after
{
    content: '';
    width: 59%;
    height: 161px;
    border-top: 2px dashed #082064;
    border-left: 2px dashed #082064;
    position: absolute;
    left: -23px;
    bottom: -102px;
    z-index: 99;
    border-right: 2px dashed #082064;
}
.line-monitoring
{
    position: relative;
}
.line-monitoring::after
{
    content: '';
    border-top: 2px dashed #082064;
    height: 45px;
    position: absolute;
    right: -119px;
    bottom: 31%;
    width: 34%;
}
.notification-line-box
{
    position: relative;
}
.notification-line-box::before
{
    content: '';
    height: 163px;
    position: absolute;
    left: -226px;
    bottom: 48%;
    width: 62%;
    border-bottom: 2px dashed #082064;
}
.object-line-ups
{
    position: relative;
}
.object-line-ups::before
{
    content: '';
    border-left: 2px dashed #082064;
    height: 163px;
    position: absolute;
    left: -36px;
    bottom: -21%;
    width: 9%;
    border-top: 2px dashed #082064;
}
.object-line-ups::after
{
    content: '';
    height: 163px;
    position: absolute;
    left: -50%;
    bottom: -22%;
    width: 38%;
    border-bottom: 2px dashed #082064;
} */
/* .arrow-right {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #082064;
    position: absolute;
    top: 48%;
    left: -16px;
  } */
/* 
.center img {
    width: 44%;
    z-index: 999;
} */
/* .list-smallbox ul 
{
    border: 1px solid #b7b7b7;
    border-radius: 20px;
} */
/* .list-smallbox ul li 
{
    padding: 10px 15px;
    border-bottom: 1px solid #b7b7b7;
}
.list-smallbox ul li:last-child
{
    border-bottom: 0;
}*/
/* .space-main 
{
    padding: 20px 0;
} */

/* .nav-list-item.accordion-item .accordion-button:not(.collapsed)
{
    background: transparent;
    box-shadow: none;
}
.nav-list-item.accordion-item .sub-list li
{
    padding: 10px 0 !important;
    color: #008ab6;
}
.header__nav .nav-link.active
{
    font-weight: 700;
}
.nav-list-item.accordion-item .sub-list li.active {
    font-weight: 700;
} */

.table-style .rdt_TableRow:nth-child(odd) {
  background: #fff!important;
}
.table-style .rdt_TableRow:nth-child(2n) {
  background: #f0f8fe!important;
}
.table-style .rdt_TableHeadRow
{
  background-color: #f8f8ff;
}

.table-style
{
  border-radius: 8px !important;
}

.table-style .rdt_TableCol 
{
  font-size: 16px;
  color: #032b47;
  width:140px;
  /* padding: 5px; */
}

.table-style.with-shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); /* Adjust shadow properties as needed */
}
.table-style .rdt_TableHead input {display:none};
.card {
  /* max-width: 280px;
  min-width: 280px; */
  width: 300px;
  height: 500px;
  margin-top: 2%;
  /* min-height: 550px; */
  padding: 1.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.train-btn
{
  background: #16c60c;
  border-color: #16c60c;
  color: #fff;
  font-weight: 500 ;
  border-radius: 6px;
  font-size: 15px;
  border: 2px solid;
  padding: 9px 23px;
  position: relative;
}
.train-count
{
  width: 23px;
  height: 23px;
  border-radius: 15px;
  position: absolute;
  right: -9px;
  top: -9px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid blue; /* Change color as needed */
  display: inline-block;
  transition: transform 0.3s;
}

.dropdown-arrow.open {
  transform: rotate(180deg); /* Rotate the arrow for open state */
}
.date-filter-box
{
  position: relative;
    padding: 5px 35px 5px 15px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 20px;
}
.anticon-close
{
  right: 12px !important;
  top: 13px !important;
}
.ant-picker-outlined
{
  background: transparent;
    border-width: 0px;
}
.number-content
{
  background: #5562DA;
    color: #fff;
    border-radius: 20px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    /* min-width: 25px; */
}
.number-content p 
{
  font-size: 13px;
}
.add-new-ques ul li 
{
  border: 1px solid #5562DA;
  padding: 2px 9px;
  border-radius: 10px;
  color: #5562DA;
  font-size: 14px;
  margin-bottom: 10px;
}
textarea{
  border: 1px solid rgb(222, 222, 222);
    border-radius: 5px !important;
}

.sidebar-icon
{
  background: transparent;
  padding: 7px;
  border-radius: 5px;
}
.sidebar .nav-links li.active .sidebar-icon
{
  background: #5562DA;

}


.chat-container {
  width: 100%;
  /* height: 650px; */
  height: 600px; 
  border: 1px solid #ccc; 
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.chat-content {
  flex-grow: 1; 
  padding: 35px 10px 15px;
  overflow-y: auto; 
}

.chat-content::-webkit-scrollbar {
  width: 8px; 
}

.chat-content::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
}

.chat-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}


.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height based on your container */
}

.no-data-container p {
  font-size: 18px;  /* Customize the font size */
  color: #555;      /* Customize the text color */
}
.btn-hover-outline:hover
{
  color: rgb(85, 98, 218) !important;
    background-color: transparent !important;
    border-color: rgb(85, 98, 218);
}
.download-list
{
  margin-bottom: 2.5rem;
}
.download-list li
{
  padding: 13px 35px;
  border-bottom: 1px solid #eaeefa;
}

.download-list li img{
  margin-right: 10px;
}

.table-style .rdt_TableCell>div {
  white-space: normal !important; 
  overflow: visible !important;   
  text-overflow: clip !important;
}

.modal-table-products .rdt_TableCol {
  font-size: 17px;
  color: #032b47;
  width: 140px;
  /* border-left: 1px solid #e0e0e0; */
}

.modal-table-products .rdt_Table
{
  /* border: 1px solid #e0e0e0; */
}
.modal-table-products .rdt_TableCell
{
  /* border-left: 1px solid #e0e0e0; */
  font-size: 16px;
}
.ant-modal-title
{
  font-size: 20px !important;
    color: #4E6A9F !important;
}
.chat-reply
{
  position: absolute;
    top: -17px;
    left: 14px;
    font-size: 13px;
    color: #546ea0;
    min-width: 190px;
}
.chat-ques
{
  position: absolute;
  top: -17px;
  right: 0px;
  font-size: 13px;
  color: #546ea0;
  min-width: 158px;
}
.chat-option
{
  display: flex;
  align-items: center;
  width: 100%;
}
.chat-option input
{
  width: 100%;
}

.sub-menu {
  display: none;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.sub-menu.open {
  display: block;
}
.sidebar-item
{
  display: flex;
      margin: auto;
      /* justify-content: center; */
      align-items: center;
      gap: 12px;
      padding: 5px 10px;
      border-radius: 12px;
      color: #fff;
}
.doc-list tr td, .doc-list tr th
{
  padding: 5px 16px !important;
}
/* upload file */
.file-input__input {
  width: 100%;
  opacity: 0;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #F2F7FF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 350px;
  border: 1px dashed #62C3EF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.file-input 
{
  width: 100%;
}
.file-img
{
  width: 45px;
}
.upload-section
{
  display: flex;
  align-items: start;
  justify-content: space-between;
  background: #F3F5F7;
  padding: 15px;
  border-radius: 5px;
}
.modal-actions {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px;
  margin-top: 33px;
  justify-content: flex-end;
}
.modal-headers
{
  display: flex;
  justify-content:space-between;
  align-items: center;
  width:100%;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover
{
  background-color: transparent !important;
}
.chatbot-style
{
  position: fixed;
   bottom: 75px;
    right: 30px;
     z-index: 1000;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
     background: #fff !important;
     border: 0;
     border-radius: 50px;
     padding: 20px;
}
.chatbot-box
{
  max-width: 400px;
   height: 500px;
    position: fixed;
     z-index: 999;
      bottom: 15px;
       right: 30px;
        background: #FFFFFF;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        border-radius: 20px;
        padding: 0;
}
.chat-header
{
 padding: 10px 15px;
 border-top-right-radius: 20px;
 border-top-left-radius: 20px;
 background: #082064;
}
.user-style
{
  background: #0a39be;
    color: #fff !important;
}
.bot-style
{
  background: #f1f3f6;
}
.sendbtn
{
  background: transparent;
    border: 0;
}
.bot-border
{
  border: 1px solid rgb(222, 222, 222);
  border-radius: 5px !important;
}
.bot-border input 
{
  border: 0px;
  border-radius: 0px !important;
}
.sendbtn:hover , :not(.btn-check)+.btn:active, .sendbtn:focus
{
  background: transparent;
  border: 0;
  box-shadow: none;
}
.no-border
{
  border: 0 !important;
}
.no-padding
{
  padding: 0 !important;
}
.border-right-line-table
{
  width: 2px;
  height: 12px;
  border-right: 1px solid #758ba2 !important;
  margin-right: 10px;
  margin-left: 10px;
}
.published
{
  background: #00FF271D;
  color: #0D9A3E;
  border-radius: 100px;
  padding: 3px 10px;
  min-width: 115px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}
.unpublished
{
  background: #FFD80026;
  color: #A89500;
  border-radius: 100px;
  padding: 3px 10px;
  min-width: 115px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}
.type-btn
{
  min-width: 250px;
}
.output-section
{
  border: 1px solid #d9d9d9;
  padding: 15px;
  height: 350px;
  overflow: auto;
}
.custom-collapse
{
  background: transparent;
  border: 0;
}
.custom-collapse .ant-collapse-item {
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 0;
  border: 1px solid #ececec;
}
.custom-collapse.ant-collapse .ant-collapse-item:last-child {
  border-bottom: 1px solid #ececec;
}

.status-active{
  color: green;
}

.status-inactive{
  color: red;
}

.status-approved {
  background: #00FF271D;
  color: #0D9A3E;
  border-radius: 100px;
  padding: 3px 10px;
  min-width: 115px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;

}

.status-rejected {
  background: #ff000036;  
  color: red;
  border-radius: 100px;
  padding: 3px 10px;
  min-width: 115px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}

.status-pending {
  background: #FFD80026;
  color: #A89500;
  border-radius: 100px;
  padding: 3px 10px;
  min-width: 115px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}

.status-default {
  color: gray;
}
.large-heading
{
  font-size: 25px;
  color: #000;
}
.small-heading
{
  font-size: 19px;
  color: #000;
}
.caption p 
{
  font-size: 14px;
  color: #57636a;
}
.body-content p
{
  font-size: 16px;
  color: #57636a;
}
.ant-input
{
  padding: 10px 10px;
}
.date .ant-picker-outlined
{
  border-width: 1px;
  padding: 12px;
}
.ant-radio-wrapper {
  margin-bottom: 15px;
}
.ant-checkbox-wrapper
{
  margin-bottom: 15px;
}
.dropdown-option
{
  width: 100%;
  border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 4px;
}
.dropdown-option a 
{
  width: 100% !important;
  display: block;
}
.dropdown-option a .ant-space
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-custom
{
  text-align: end;
  position: absolute;
  width: 70px;
  right: 30px;
  z-index: 999;
  top: 28px;
}

.table-style.settings-tables .rdt_TableCol 
{
  width:180px;
}
.table-custom .ant-dropdown-trigger
{
  padding: 7px;
  background: #e3e3e3;
  border-radius: 4px;
}
.settings-file h1, .settings-file h2, .settings-file h3, .settings-file h4, .settings-file h5, .settings-file h6, .settings-file p, .settings-file label{
  color: #000;
}

.settings-file .ant-btn-primary.preview-btn
{
  background: #1daa61;
  color: #fff;
  border-radius: 50px;
}
.settings-file .ant-btn-primary.preview-btn:hover, .settings-file .ant-btn-primary.preview-btn:not(:disabled):not(.ant-btn-disabled):hover
{
  background: #18ab5f;
  color: #fff;
}
/*  */
.floating-label {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.floating-label label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #aaa;
  transition: all 0.3s ease;
  pointer-events: none;
  background: white;
  padding: 0 4px;
}

.floating-label input {
  padding-top: 16px; /* Prevent overlap */
  height: 40px;
}

.floating-label.active label,
.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
  top: 4px;
  font-size: 12px;
  color: #333;
}
.register-box
{
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.template-heading
{
  font-size: 20px;
}